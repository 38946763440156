import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layout/Layout";
import ButtonIcon from "../components/common/ButtonIcon";
import { FiPlus } from "react-icons/fi";
import AddSeriesModal from "../components/common/Modal/AddSeriesModal";
// import DataTable from "../components/common/Table/DataTable";
import DataTable from "react-data-table-component";
import useColumns from "../components/hooks/useColumns";
import BreadCrum from "../components/common/BreadCrum";
import { SelectedSeriesData } from "../components/context/SeriesContext";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/ImageUrl";
import {
  CategoryList,
  deleteSeriesData,
  GenreList,
  getSeriesList,
} from "../Action/AdminAction";
import { toast } from "react-toastify";
import EditSeriesModal from "../components/common/Modal/EditSeriesModal";
import DeleteModal from "../components/common/Modal/DeleteModal";
import { MdKeyboardArrowRight } from "react-icons/md";
import DataTable2 from "../components/common/Table/DataTable2";
import debounce from "lodash/debounce";
import { SearchDataChange } from "../components/context/SearchQueryContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { searchQuery, setSearchQuery } = SearchDataChange();
  const [search, setSearch] = useState("");
  const [editModal, setEditModal] = useState({
    open: false,
    data: null,
  });
  const [loader, setLoader] = useState(true);
  const [viewEpisode, setViewEpisode] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [seriesList, setSeriesList] = useState([]);
  const [rowData, setRowData] = useState();
  const [pagination, setPagination] = useState({
    totalPage: 0,
    totalRecords: 0,
    page: 1,
    limit: 10,
  });

  const { selectedSeries, setSelectedSeries } = SelectedSeriesData();

  const getSeriesData = async () => {
    const response = await getSeriesList({
      page: pagination.page,
      limit: pagination.limit,
      search: search,
      isIgnore: false,
    });

    const totalPages = Math.ceil(response.data.data.length / pagination.limit);
    setPagination((prevState) => ({
      ...prevState,
      totalPage: totalPages,
      totalRecords: response.data.data.length,
    }));
    if (
      response?.data &&
      response?.data?.data &&
      response?.data?.data?.seriesList
    ) {
      setSeriesList(response?.data?.data?.seriesList);
      setLoader(false);
    }
  };

  useEffect(() => {
    getSeriesData();
  }, [open, editModal, pagination.limit, pagination.page, search]);

  const handleView = (id) => {
    navigate(`/episode/${id}`);
  };

  const deleteSeriesById = async () => {
    const formData = new FormData();
    formData.append("series_id", rowData?._id);
    const response = await deleteSeriesData(formData);
    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      setDeleteData(false);
      toast.success(response?.data?.ResponseMsg);
      setDeleteData(false);
      getSeriesData();
    } else {
      setDeleteData(false);
      toast.error(response?.data?.ResponseMsg);
    }
  };

  const handleDelete = (row) => {
    setRowData(row);
    setDeleteData(true);
  };

  const handleEdit = (row) => {
    setRowData(row);
    setEditModal(true);
  };

  const getCategoryListData = async () => {
    const response = await GenreList();

    response &&
      response?.data &&
      response?.data?.data &&
      setCategoryData(response?.data?.data?.data);
  };

  useEffect(() => {
    getCategoryListData();
  }, [open]);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
      setPagination({
        totalPage: 0,
        totalRecords: 0,
        page: 1,
        limit: 10,
      });
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const columns = [
    {
      name: "Series Name",
      selector: (row) => (
        <div className="flex gap-2 items-center">
          <img
            src={row?.series_image}
            alt="Profile"
            className="max-w-[48px] h-[48px] rounded-full object-cover"
          />
          <p className="text-lg text-white font-medium capitalize">
            {row?.series_name}
          </p>
        </div>
      ),
      width: "400px",
      // getGroupingValue: row => `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Episode",
      selector: (row) => (
        <div className="flex gap-2 items-center justify-end">
          <p className="text-white text-lg font-medium capitalize">
            {row.episode}
          </p>
        </div>
      ),
    },
    {
      name: "Category",
      selector: (row) => (
        <div className="flex gap-2 items-center">
          <p className="text-white text-lg font-medium capitalize">
            {row.category}
          </p>
        </div>
      ),
    },
    {
      name: "Price",
      selector: (row) => (
        <div className="flex gap-2 items-center">
          <p className="text-white text-lg font-medium capitalize">
            {row.price}
          </p>
        </div>
      ),
    },
    {
      name: "Episodes",
      width: "170px",
      selector: (row) => (
        <button
          className={
            "buttonBg text-white text-xl font-semibold rounded-[10px] py-2 px-3 flex items-center justify-center gap-3"
          }
          onClick={() => {
            handleView(row?._id);
          }}
        >
          <span className="text-[14px] font-normal"> Episodes</span>
          <div className="flex items-center justify-center bg-white w-[20px] h-[20px] rounded-full">
            <MdKeyboardArrowRight className="text-[#F45944] font-medium" />
          </div>
        </button>
      ),
    },
    {
      cell: (row) => (
        <div className="min-w-[50px] flex justify-between items-center gap-6">
          <div
            className="cursor-pointer"
            onClick={() => {
              setEditModal({ open: true, data: row });
            }}
          >
            <img src={`${ImageUrl}assets/icons/editIcon.svg`} alt="edit icon" />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleDelete(row);
            }}
          >
            <img src={`${ImageUrl}assets/icons/trash.svg`} alt="delete icon" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Layout title="Dashboard">
        {seriesList?.length < 0 ? (
          <div className="flex items-center flex-col justify-center h-[calc(92vh-50px)] space-y-5">
            <img src="/assets/images/DashBoard.png" alt="not found" />
            <p className="text-textColor font-bold text-3xl">
              lorem ipsum dummy text
            </p>
            <p className="text-xl font-semibold text-textColor w-[420px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <div className="w-[154px]">
              <ButtonIcon
                text="Add"
                className="w-full"
                icon={<FiPlus className="text-[#F45944] font-medium" />}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <BreadCrum
              back={false}
              openModal={() => {
                setOpen(true);
              }}
              title="Series"
              btnText="Add New "
            />
            <div className="h-full flex flex-col justify-between bg-[#020102] rounded-[15px] relative overflow-y-scroll">
              <DataTable2
                data={seriesList}
                columns={columns}
                pagination={pagination}
                setPagination={setPagination}
                loader={loader}
              />
            </div>
          </>
        )}
        <AddSeriesModal
          openModal={open}
          closeModal={() => {
            setOpen(false);
          }}
          categoryData={categoryData}
        />
        <EditSeriesModal
          openModal={editModal}
          closeModal={() => {
            setEditModal({
              open: false,
              data: null,
            });
          }}
          rowData={rowData}
        />
        <DeleteModal
          openModal={deleteData}
          closeModal={() => {
            setDeleteData(false);
          }}
          handleSubmit={deleteSeriesById}
          title={"delete series"}
        />
      </Layout>
    </>
  );
};

export default Dashboard;
