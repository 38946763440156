import React, { useState } from "react";
import Button from "../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import PasswordInput from "../components/common/From/PasswordInput";
import { ImageUrl } from "../config/ImageUrl";
import { ResetAdminPassword } from "../Action/AdminAction";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../components/lib/schema";
import { values } from "lodash";

const ResetPassword = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      newpassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const email = localStorage.getItem("email");

      const formData = new FormData();
      formData.append("email", email);
      formData.append("new_password", values?.password);

      const response = await ResetAdminPassword(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        navigate("/");
      } else {
        toast.error(response?.data?.ResponseMsg);
      }
    },
  });

  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen bg-center	 bg-[url('../public/assets/images/MainBg.png')] bg-cover	">
        <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
          <div className="overlay">
            <img
              className=" light-purple-filter"
              src="assets/images/resetPassword.png"
              alt="hand-logo"
            />
          </div>
        </div>
        <div className="w-full  flex flex-col items-center justify-center h-screen py-20 lg:pr-36 sm:pr-10 px-5">
          <div className="bg-[#100c0c] shadow-4xl w-full h-full rounded-2xl flex items-center justify-center">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <h2 className=" text-1xl font-semibold text-center text-white">
                Set New Password
              </h2>
              <div className=" flex justify-center">
                <p className="text-xl font-normal  text-textColor text-center  max-w-[334px]">
                  Your new password must be different from previously used
                  password.
                </p>
              </div>

              <div className="mt-8 flex flex-col gap-y-7 px-5 md:px-10 lg:px-10 2xl:px-20">
                <PasswordInput
                  placeholder=" New password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  icon={
                    <img
                      src={`${ImageUrl}assets/icons/lock.svg`}
                      alt="password"
                    />
                  }
                />
                <div>
                  <PasswordInput
                    placeholder="Confirm password"
                    name="newpassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.newpassword}
                    touched={formik.touched.newpassword}
                    icon={
                      <img
                        src={`${ImageUrl}assets/icons/lock.svg`}
                        alt="password"
                      />
                    }
                  />
                </div>
                <Button text="Reset Password" className="w-full" />
                <div className="flex justify-center mt-32">
                  <Link
                    className="flex items-center gap-3 text-textColor text-lg	font-normal "
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <GoArrowLeft /> <span>Back to Sign In</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
