import React, { useState } from "react";
import Button from "../components/common/Button";
import { GoArrowLeft } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "../components/common/From/OtpInput";
import { verifyOtp } from "../Action/AdminAction";
import { toast } from "react-toastify";
import { ImageUrl } from "../config/ImageUrl";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const finalotp = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}`;
    const email = localStorage.getItem("email");
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", finalotp);
    const response = await verifyOtp(formData);


    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      navigate("/reset-password");
    } else {
      toast.error(response?.data?.ResponseMsg);
    }
  };

  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen bg-center	 bg-[url('../public/assets/images/MainBg.png')] bg-cover	">
      <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
        <div className="overlay">
          <img
            className=" light-purple-filter"
            src={`${ImageUrl}assets/images/verifyOtp.png`}
            alt="hand-logo"
          />
        </div>
      </div>
      <div className="w-full  flex flex-col items-center justify-center h-screen py-20 lg:pr-36 sm:pr-10 px-5">
        <div className="bg-[#100c0c] shadow-4xl w-full h-full rounded-2xl flex items-center justify-center">
          <form className="w-full" method="POST">
            <h2 className=" text-1xl font-semibold text-center text-white">
              Authentication
            </h2>
            <div className=" flex justify-center">
              <p className="text-xl font-normal  text-textColor text-center  max-w-[303px]">
                Enter the verification code we just sent to your email address.
              </p>
            </div>
            <div className="mt-8 flex flex-col gap-y-7 px-5 md:px-10 lg:px-10 2xl:px-20">
              <div className="flex justify-center gap-x-4 w-full">
                {otp.map((value, index) => (
                  <OTPInput
                    key={index}
                    id={`otp-${index}`}
                    name={`otp-${index}`}
                    prevElement={index > 0 ? `otp-${index - 1}` : null}
                    nextElement={
                      index < otp.length - 1 ? `otp-${index + 1}` : null
                    }
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center w-full">
                <Button
                  text="Send Code"
                  className="w-full"
                  onClick={handleOtpSubmit}
                />
              </div>

              <div className="flex justify-center mt-40">
                <Link
                  className="flex items-center gap-3 text-textColor text-lg	font-normal "
                  // to={navigate("/")}
                >
                  <GoArrowLeft /> <span>Back to Sign In</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
