import React from "react";

const SelectBox = ({
  name,
  defaultOption,
  options,
  onChange,
  value,
  touched,
  error,
  onBlur,
}) => {
  return (
    <div className="flex flex-col">
      <select
        name={name}
        className={`w-full  py-4 px-3 text-textColor  rounded-[15px] bg-inputBG shadow-3xl outline-none ${
          touched &&
          error && //handle errors
          "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
        }`}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      >
        <option value="New York">{defaultOption}</option>
        {options && options?.length > 0 ? (
          options.map((city, index) => (
            <option key={index} value={city?.category_name} className="">
              {city?.category_name}
            </option>
          ))
        ) : (
          <p className="text-white">Nothing to show here </p>
        )}
      </select>
      {touched && error ? (
        <div className="text-red-500 text-xs mt-1">{error}</div>
      ) : null}
    </div>
  );
};

export default SelectBox;
