import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import InputWithIcon from "../From/InputWithIcon";
import Button from "../Button";
import { addNewPlan, planUpdate } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { SelectedSeriesData } from "../../context/SeriesContext";
import { useForkRef } from "@mui/material";
import { useFormik } from "formik";
import { PlanSchema } from "../../lib/schema";

const AddNewPlan = ({ openModal, closeModal, type = "add" }) => {
  const { selectedPlan, setSelectedPlan } = SelectedSeriesData();

  const formik = useFormik({
    initialValues: {
      coin: "",
      price: "",
    },
    validationSchema: PlanSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      type == "edit" && formData.append("plan_id", selectedPlan?._id);
      formData.append("coin", values?.coin);
      formData.append("price", values?.price);
      const response =
        type == "edit"
          ? await planUpdate(formData)
          : await addNewPlan(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        closeModal();
      } else {
        toast.error(response?.data?.ResponseMsg);
      }
    },
  });

  useEffect(() => {
    if (type == "edit") {
      formik.values.coin = selectedPlan?.coin;
      formik.values.price = selectedPlan?.price;
    }
  }, [selectedPlan]);

  return (
    <>
      <Modal
        title={type === "edit" ? "Edit Plan" : "Add Plan"}
        openModal={openModal}
        closeModal={() => {
          closeModal();
          setSelectedPlan();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="px-5 pt-3 pb-5 space-y-5">
            <InputWithIcon
              type="number"
              name="coin"
              placeholder="Coin"
              value={formik.values.coin}
              error={formik.errors.coin}
              touched={formik.touched.coin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <InputWithIcon
              type="number"
              name="price"
              placeholder="Price"
              value={formik.values.price}
              error={formik.errors.price}
              touched={formik.touched.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="border-t border-inputBG w-full px-5 py-4">
            <Button
              text={`${type == "edit" ? "Save" : "Add"}`}
              className="w-full"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddNewPlan;
