import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import FileUpload from "../From/FileUpload";
import InputWithIcon from "../From/InputWithIcon";
import SelectBox from "../From/SelectBox";
import Button from "../Button";
import { CategoryList, addNewSeries } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AddSeriesSchema } from "../../lib/schema";

const cities = [
  "Popular ",
  "TV shows",
  "male audiences",
  "female audiences",
  "action",
];

const AddSeriesModal = ({ openModal, closeModal, categoryData }) => {
  // const [file, setFile] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [bannerImage, setBannerImage] = useState();

  const formik = useFormik({
    initialValues: {
      series_name: "",
      price: "",
      category: "",
      series_desc: "",
    },
    validationSchema: AddSeriesSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      for (const type in videoFiles) {
        if (typeof videoFiles[type] == "object") {
          formData.append("episode_url", videoFiles[type]);
        }
      }
      for (const type in thumbnails) {
        if (typeof thumbnails[type] == "object") {
          formData.append("episode_thumbnail", thumbnails[type]);
        }
      }
      formData.append("series_image", bannerImage && bannerImage[0]);
      formData.append("series_name", values?.series_name);
      formData.append("price", values?.price);
      formData.append("category", values?.category);
      formData.append("series_desc", values?.series_desc);

      const response = await addNewSeries(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        setBannerImage();
        setThumbnails([]);
        setVideoFiles([]);
        formik.values.series_name = "";
        formik.values.price = "";
        formik.values.category = "";
        formik.values.series_desc = "";
        closeModal();
      } else {
        toast.error(response?.data?.ResponseMsg);
        setBannerImage();
        setVideoFiles([]);
        setThumbnails([]);
        // closeModal();
      }
    },
  });

  const handleVideoChange = async (event) => {
    const files = Array.from(event.target.files);
    const newVideoFiles = [];

    const newThumbnails = [];

    for (const file of files) {
      newVideoFiles.push(file);
      try {
        const thumbnailFile = await createThumbnail(file);
        newThumbnails.push(thumbnailFile);
      } catch (error) {
        console.error("Error creating thumbnail:", error);
      }
    }

    setVideoFiles(newVideoFiles);
    setThumbnails(newThumbnails);
  };

  // generate thumbnail
  const createThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        video.currentTime = 2; // Capture frame at 10 seconds into the video
        video.onseeked = async () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert canvas to Blob
          canvas.toBlob((blob) => {
            if (blob) {
              const thumbnailFile = new File(
                [blob],
                file.name.replace(/\.\w+$/, ".jpg"),
                {
                  type: "image/jpeg",
                  lastModified: new Date().getTime(),
                }
              );
              resolve(thumbnailFile);
            } else {
              reject(new Error("Canvas to Blob conversion failed"));
            }
          }, "image/jpeg");
        };
      };

      video.onerror = (error) => reject(error);
      video.src = URL.createObjectURL(file);
    });
  };

  return (
    <>
      <Modal title="Add Series" openModal={openModal} closeModal={closeModal}>
        <form onSubmit={formik.handleSubmit}>
          <div className="px-5 pt-3 pb-5 space-y-4">
            <div className="grid grid-cols-2 gap-4 ">
              <FileUpload
                label="Upload Banner"
                onChange={(e) => {
                  setBannerImage(e.target.files);
                }}
                files={bannerImage}
              />
              <FileUpload
                label="Upload Episodes"
                name="file"
                onChange={handleVideoChange}
                files={videoFiles}
              />
            </div>

            <div className="">
              <InputWithIcon
                placeholder="Series Name"
                name="series_name"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.series_name}
                error={formik.errors.series_name}
                value={formik.values.series_name}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <InputWithIcon
                placeholder="Price"
                name="price"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.price}
                error={formik.errors.price}
                value={formik.values.price}
              />
              <SelectBox
                label="Branch Location"
                name="category"
                defaultOption="Select Category"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                touched={formik.touched.category}
                error={formik.errors.category}
                value={formik.values.category}
                options={categoryData}
              />
            </div>
            <div className="flex flex-col">
              <textarea
                name="series_desc"
                className={`w-full text-white  rounded-[15px] bg-inputBG shadow-3xl outline-none h-[150px] px-4 py-3 ${
                  formik.errors.series_desc &&
                  formik.touched.series_desc && //handle errors
                  "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
                }`}
                placeholder="Description"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                values={formik.values.series_desc}
              ></textarea>
              {formik.errors.series_desc && formik.touched.series_desc ? (
                <div className="text-red-500 text-xs mt-1">
                  {formik.errors.series_desc}
                </div>
              ) : null}
            </div>
          </div>
          <div className="border-t border-inputBG w-full px-5 py-4">
            <Button
              text="Create"
              className="w-full"
              // onClick={handleAddNewSeries}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddSeriesModal;
