import React from "react";
import Modal from "./Modal";
import { ImageUrl } from "../../../config/ImageUrl";
import Button from "../Button";

const DeleteModal = ({ openModal, closeModal, title, handleSubmit }) => {
  return (
    <>
      <Modal openModal={openModal} closeModal={closeModal}>
        <div className="flex flex-col items-center px-5 pt-3 pb-5 space-y-6">
          <div>
            <img
              src={`${ImageUrl}/assets/images/DeleteModal.png`}
              alt="trash"
            />
          </div>
          <p className="text-white font-semibold text-3xl capitalize">{title}</p>
          <p className="text-textColor text-xl font-normal w-[223px] text-center">
            Are you sure you want to {title}?
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4 w-full border-t border-inputBG pt-4 px-5 pb-4">
          <button
            className="w-full bg-textColor shadow-3xl text-white  text-xl font-normal rounded-[10px]"
            onClick={closeModal}
          >
            No
          </button>
          <Button text="Yes" className="w-full" onClick={handleSubmit} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
