import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import FileUpload from "../From/FileUpload";
import SelectBox from "../From/SelectBox";
import InputWithIcon from "../From/InputWithIcon";
import Button from "../Button";
import { GenreList, updateSeriesData } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import { useFormik } from "formik";
import { AddSeriesSchema } from "../../lib/schema";

const EditSeriesModal = ({ openModal, closeModal }) => {
  const [bannerImage, setBannerImage] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [categoryData, setCategoryData] = useState();
  const formik = useFormik({
    initialValues: {
      series_name: "",
      price: "",
      category: "",
      series_desc: "",
    },
    validationSchema: AddSeriesSchema,
    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append("series_id", openModal?.data?._id);
      formData.append("series_image", bannerImage);
      formData.append("series_name", values?.series_name);
      formData.append("price", values?.price);
      formData.append("category", values?.category);
      formData.append("series_desc", values?.series_desc);

      const response = await updateSeriesData(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        setBannerImage();
        setShowImage(true);
        closeModal();
      } else {
        toast.error(response?.data?.ResponseMsg);
      }
    },
  });

  const getCategoryListData = async () => {
    const response = await GenreList();
    response &&
      response?.data &&
      response?.data?.data &&
      setCategoryData(response?.data?.data?.data);
  };

  useEffect(() => {
    getCategoryListData();
  }, []);

  useEffect(() => {
    formik.values.series_name = openModal?.data?.series_name;
    formik.values.series_desc = openModal?.data?.series_desc;
    formik.values.price = openModal?.data?.price;
    formik.values.category = openModal?.data?.category;
    // setBannerImage(rowData?.series_image);
  }, [openModal?.data]);
  return (
    <Modal
      title="Edit Series"
      openModal={openModal?.open}
      closeModal={closeModal}
    >
      <form onSubmit={formik.handleSubmit} className="overflow-y-scroll h-full">
        <div className="space-y-4 px-5 pt-3 pb-5">
          {openModal?.data?.series_image && showImage ? (
            <div className="relative w-full">
              <img
                src={openModal?.data?.series_image}
                alt="not found"
                className="w-full h-[300px] object-cover rounded-[10px]"
              />
              <div
                className="absolute w-[30px] h-[30px] flex items-center justify-center rounded-md bg-inputBG top-2 right-3 z-20"
                onClick={() => {
                  setShowImage(false);
                }}
              >
                <IoClose className="text-white text-xl font-semibold" />
              </div>
            </div>
          ) : (
            ""
          )}
          {!showImage && (
            <FileUpload
              label="Upload Banner"
              onChange={(e) => {
                setBannerImage(e.target.files[0]);
              }}
              files={bannerImage}
            />
          )}
          <InputWithIcon
            placeholder="Series Name"
            name="series_name"
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            touched={formik.touched.series_name}
            error={formik.errors.series_name}
            value={formik.values.series_name}
          />
          <div className="grid grid-cols-2 gap-3">
            <InputWithIcon
              type="number"
              placeholder="Price"
              name="price"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              touched={formik.touched.price}
              error={formik.errors.price}
              value={formik.values.price}
            />
            <SelectBox
              label="Branch Location"
              name="category"
              defaultOption="Select category"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              touched={formik.touched.category}
              error={formik.errors.category}
              value={formik.values.category}
              options={categoryData}
            />
          </div>
          <div className="flex flex-col">
            <textarea
              name="series_desc"
              className={`w-full text-white  rounded-[15px] bg-inputBG shadow-3xl outline-none h-[150px] px-4 py-3 ${
                formik.errors.series_desc &&
                formik.touched.series_desc && //handle errors
                "placeholder:text-red-400 caret-red-400 ring-1 ring-red-400 focus-visible:ring-red-400"
              }`}
              placeholder="Description"
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              value={formik.values.series_desc}
            ></textarea>
            {formik.errors.series_desc && formik.touched.series_desc ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.series_desc}
              </div>
            ) : null}
          </div>
        </div>
        <div className="border-t border-inputBG w-full px-5 py-4">
          <Button text="Save" className="w-full" />
        </div>
      </form>
    </Modal>
  );
};

export default EditSeriesModal;
