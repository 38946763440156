import React, { useState } from "react";
import InputWithIcon from "../components/common/From/InputWithIcon";
import Button from "../components/common/Button";
import { GoArrowLeft } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/ImageUrl";
import { forgetAdminPassword } from "../Action/AdminAction";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "../components/lib/schema";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("email", values.email);
      const response = await forgetAdminPassword(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        localStorage.setItem("email", response?.data?.data?.email);
        navigate("/verify-otp");
      } else {
        toast.error(response?.data?.ResponseMsg);
      }
    },
  });

  const backToSignIn = () => {
    navigate(-1);
  };

  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen bg-center	 bg-[url('../public/assets/images/MainBg.png')] bg-cover	">
      <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
        <div className="overlay">
          <img
            className=" light-purple-filter"
            src="assets/images/ForgetPassword.png"
            alt="hand-logo"
          />
        </div>
      </div>
      <div className="w-full  flex flex-col items-center justify-center h-screen py-20 lg:pr-36 sm:pr-10 px-5">
        <div className="bg-[#100c0c] shadow-4xl w-full h-full rounded-2xl flex items-center justify-center">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <h2 className=" text-1xl font-semibold text-center text-white">
              Forgot Password?
            </h2>
            <p className="text-xl font-normal  text-textColor text-center">
              No worries, we’ll send you instruction.
            </p>

            <div className="mt-8 flex flex-col gap-y-7 px-5 md:px-10 lg:px-10 2xl:px-20">
              <InputWithIcon
                placeholder={"Email"}
                label="Email"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email}
                touched={formik.touched.email}
                icon={
                  <img
                    src={`${ImageUrl}assets/icons/EmailIcon.svg`}
                    alt="email"
                  />
                }
              />

              <Button text="Send Code" className="w-full" />
              <div className="flex justify-center mt-40">
                <Link
                  className="flex items-center gap-3 text-textColor text-lg	font-normal "
                  onClick={backToSignIn}
                >
                  <GoArrowLeft /> <span>Back to Sign In</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
