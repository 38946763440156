import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import CheckBox from "../From/CheckBox";
import Button from "../Button";
import { ImageUrl } from "../../../config/ImageUrl";
import {
  addNewSeriesToCategory,
  getSeriesList,
} from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AddSeriesToCategory = ({ openModal, closeModal }) => {
  const [seriesList, setSeriesList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  const params = useParams();

  const getAllSeriedData = async () => {
    const formData = new FormData();
    formData.append("isIgnore", true);
    const response = await getSeriesList(formData);
    response?.data &&
      response?.data?.data &&
      response?.data?.data?.seriesList &&
      setSeriesList(response?.data?.data?.seriesList);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // selectedList.push(value);
      setSelectedList([...selectedList, value]);
    } else {
      setSelectedList(selectedList && selectedList.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    if (!openModal) return;
    getAllSeriedData();
  }, [openModal]);

  // add series to category

  const handleAddNewSeries = async () => {
    const category = localStorage.getItem("category_name");
    const formData = new FormData();

    formData.append("category_id", category);
    selectedList &&
      selectedList?.map((val, index) => {
        formData.append(`series_ids[${index}]`, val);
      });

    const response = await addNewSeriesToCategory(formData);

    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      closeModal();
    } else {
      toast.error(response?.data?.ResponseMsg);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return (
    <>
      <Modal title="Add Series" openModal={openModal} closeModal={closeModal}>
        <div className="px-5 pt-3 pb-5 h-[500px] overflow-auto noScrollbar">
          {seriesList?.length > 0 ? (
            seriesList?.map((val, index) => {
              return (
                <CheckBox
                  key={index}
                  name={val?.series_name}
                  image={val?.series_image}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={val?._id}
                />
              );
            })
          ) : (
            <div className="flex justify-center items-center">
              {" "}
              <p className="text-white text-lg font-medium">
                Nothing to show here
              </p>
            </div>
          )}
          {/* {seriesList?.map((val, index) => {
            return (
              
            );
          })} */}
        </div>
        <div className="border-t border-inputBG w-full px-5 py-4">
          <Button text="Add" className="w-full" onClick={handleAddNewSeries} />
        </div>
      </Modal>
    </>
  );
};

export default AddSeriesToCategory;
