import React, { useState } from "react";
import Modal from "./Modal";
import InputWithIcon from "../From/InputWithIcon";
import Button from "../Button";
import { ImageUrl } from "../../../config/ImageUrl";
import AddSeriesToCategory from "./AddSeriesToCategory";
import { addNewCategory } from "../../../Action/AdminAction";
import { toast } from "react-toastify";
import { NewCategoryData } from "../../context/CategoryContext";

const AddNewCategory = ({ openModal, closeModal }) => {
  const { seriesOpen, setSeriesOpen } = NewCategoryData();
  // const [seriesOpen, setSeriesOpen] = useState(false);
  const [categoryName, setCategoryName] = useState();

  const serisModalClose = () => {
    setSeriesOpen(false);
  };

  const AddNewCategoryDataa = async () => {
    const formData = new FormData();

    formData.append("category_name", categoryName);
    const response = await addNewCategory(formData);

    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      localStorage.setItem("category_name", response?.data?.data?._id);
      setCategoryName("");
      closeModal();
      setSeriesOpen(true);
    } else {
      toast.error(response?.data?.ResponseMsg);
    }
  };
  return (
    <>
      <Modal
        title="Add New Genre"
        openModal={openModal}
        closeModal={closeModal}
      >
        <div className="px-5 pt-3 pb-5">
          <InputWithIcon
            type="text"
            placeholder="Genre Name"
            name="category_name"
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
          />
        </div>
        <div className="border-t border-inputBG w-full px-5 py-4">
          <Button
            text="Create"
            className="w-full"
            onClick={AddNewCategoryDataa}
          />
        </div>
      </Modal>

      <AddSeriesToCategory
        openModal={seriesOpen}
        closeModal={serisModalClose}
      />
    </>
  );
};

export default AddNewCategory;
