import React, { useEffect, useState } from "react";
import BreadCrum from "../components/common/BreadCrum";
import Layout from "../components/common/Layout/Layout";
import useColumns from "../components/hooks/useColumns";
import DataTable from "../components/common/Table/DataTable";
import AddEpisodeModal from "../components/common/Modal/AddEpisodeModal";
import { useParams } from "react-router-dom";
import {
  deleteEpisodeById,
  getEpisodesBySeries,
  getSeriesList,
  updateEpisodeData,
} from "../Action/AdminAction";
import { SelectedSeriesData } from "../components/context/SeriesContext";
import { toast } from "react-toastify";
import DeleteModal from "../components/common/Modal/DeleteModal";
import Modal from "../components/common/Modal/Modal";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa";
import { ImageUrl } from "../config/ImageUrl";
import DataTable2 from "../components/common/Table/DataTable2";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "../components/common/Modal/ModalComponents";
import { IoMdClose } from "react-icons/io";

export const ToggleSwitch = ({ data, handleClick }) => {
  const [isLocked, setIsLocked] = useState(data);
  return (
    <div className="bg-[#838383] flex rounded-full h-[38px] w-[130px]">
      <div
        className={`${
          isLocked === "free" && "bg-[#F45944]"
        }  rounded-full w-[50%]  flex items-center justify-center cursor-pointer`}
        onClick={() => {
          setIsLocked("free");
          handleClick();
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3661_1180)">
            <path
              d="M14.244 0C11.4595 0 9.20215 2.2573 9.20215 5.04184V7.06543H11.7231V5.04184C11.7231 3.64957 12.8518 2.5209 14.244 2.5209C15.6363 2.5209 16.765 3.64957 16.765 5.04184V5.71637C16.765 6.03996 17.0273 6.3023 17.3509 6.3023H18.7C19.0236 6.3023 19.2859 6.03996 19.2859 5.71637V5.04184C19.2859 2.2573 17.0286 0 14.244 0Z"
              fill="white"
            />
            <path
              d="M0.713867 10V18.2373C0.713867 19.2093 1.50461 20 2.47656 20H11.8906C12.8626 20 13.6533 19.2093 13.6533 18.2373V10C13.6533 9.02805 12.8626 8.2373 11.8906 8.2373H2.47656C1.50461 8.2373 0.713867 9.02805 0.713867 10ZM7.18359 11.7676C8.15555 11.7676 8.94629 12.5583 8.94629 13.5303C8.94629 14.2968 8.45426 14.9504 7.76953 15.1925V15.8838C7.76953 16.2074 7.50719 16.4697 7.18359 16.4697C6.86 16.4697 6.59766 16.2074 6.59766 15.8838V15.1925C5.91293 14.9504 5.4209 14.2969 5.4209 13.5303C5.4209 12.5583 6.21164 11.7676 7.18359 11.7676Z"
              fill="white"
            />
            <path
              d="M7.18359 14.1211C7.50989 14.1211 7.77441 13.8566 7.77441 13.5303C7.77441 13.204 7.50989 12.9395 7.18359 12.9395C6.85729 12.9395 6.59277 13.204 6.59277 13.5303C6.59277 13.8566 6.85729 14.1211 7.18359 14.1211Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3661_1180">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        className={`${
          isLocked === "paid" && "bg-[#F45944]"
        } flex rounded-full justify-center items-center  w-[50%] cursor-pointer`}
        onClick={() => {
          setIsLocked("paid");
          handleClick();
        }}
      >
        <svg
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.04188 1C4.25734 1 2 3.2573 2 6.04184V9.06543H4.52094V6.04184C4.52094 4.64957 5.64961 3.5209 7.04188 3.5209C8.43414 3.5209 9.56281 4.64957 9.56281 6.04184V16.7164C9.56281 17.04 9.82516 17.3023 10.1487 17.3023H11.4978C11.8214 17.3023 12.0837 17.04 12.0837 16.7164V6.04184C12.0837 3.2573 9.82641 1 7.04188 1Z"
            fill="white"
          />
          <path
            d="M0.713867 10V18.2373C0.713867 19.2093 1.50461 20 2.47656 20H11.8906C12.8626 20 13.6533 19.2093 13.6533 18.2373V10C13.6533 9.02805 12.8626 8.2373 11.8906 8.2373H2.47656C1.50461 8.2373 0.713867 9.02805 0.713867 10ZM7.18359 11.7676C8.15555 11.7676 8.94629 12.5583 8.94629 13.5303C8.94629 14.2968 8.45426 14.9504 7.76953 15.1925V15.8838C7.76953 16.2074 7.50719 16.4697 7.18359 16.4697C6.86 16.4697 6.59766 16.2074 6.59766 15.8838V15.1925C5.91293 14.9504 5.4209 14.2969 5.4209 13.5303C5.4209 12.5583 6.21164 11.7676 7.18359 11.7676Z"
            fill="white"
          />
          <path
            d="M7.18359 14.1211C7.50989 14.1211 7.77441 13.8566 7.77441 13.5303C7.77441 13.204 7.50989 12.9395 7.18359 12.9395C6.85729 12.9395 6.59277 13.204 6.59277 13.5303C6.59277 13.8566 6.85729 14.1211 7.18359 14.1211Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

const SeriesDetails = () => {
  const [pagination, setPagination] = useState({
    totalPage: 0,
    totalRecords: 0,
    page: 1,
    limit: 10,
  });
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [openEpisode, setOpenEpisode] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [episodeData, setEpisodeData] = useState([]);
  const params = useParams();

  const getEpisodeData = async () => {
    const formData = new FormData();
    formData.append("series_id", params?.id);
    formData.append("page", pagination.page);
    formData.append("limit", pagination.limit);
    const response = await getEpisodesBySeries(formData);
    const totalPages = Math.ceil(
      response.data.data.totalRecord / pagination.limit
    );
    setPagination((prevState) => ({
      ...prevState,
      totalPage: totalPages,
      totalRecords: response.data.data.data.length,
    }));
    if (response && response?.data && response?.data?.data) {
      setEpisodeData(response?.data?.data?.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getEpisodeData();
  }, [open, pagination.limit, pagination.page]);

  const handleDelete = (row) => {
    setRowData(row);
    setDeleteData(true);
  };

  const deleteEpisodeData = async () => {
    const formData = new FormData();

    formData.append("episode_id", rowData?._id);
    const response = await deleteEpisodeById(formData);

    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      setDeleteData(false);
      getEpisodeData();
    } else {
      toast.error(response?.data?.ResponseMsg);
    }
  };

  const handleEpisodeToggle = async (id) => {
    const formData = new FormData();
    formData.append("episode_id", id);
    const response = await updateEpisodeData(formData);
    if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
      toast.success(response?.data?.ResponseMsg);
      getEpisodeData();
    } else {
      toast.error(response?.data?.ResponseMsg);
    }
  };

  const handleView = (row) => {
    setRowData(row);
    setOpenEpisode(true);
  };

  const columns = [
    {
      name: "Episode Name",
      selector: (row, index) => (
        <div className="flex gap-3 items-center">
          <FaPlay
            className="text-lg font-medium text-white cursor-pointer"
            onClick={() => {
              handleView(row);
            }}
          />
          <p className="text-base font-medium">
            {/* Episode {index + (pagination.limit * pagination.page - 10) + 1} */}
            Episode {(pagination.page - 1) * pagination.limit + index + 1}
          </p>
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex gap-10">
          <ToggleSwitch
            data={row?.episode_type}
            handleClick={() => {
              handleEpisodeToggle(row?._id);
            }}
          />
          <div
            className="cursor-pointer flex justify-end"
            onClick={() => {
              handleDelete(row);
            }}
          >
            <img src={`${ImageUrl}assets/icons/trash.svg`} alt="delete icon" />
          </div>
        </div>
      ),
      width: "300px",
    },
  ];

  return (
    <>
      <Layout>
        <BreadCrum
          back={true}
          title={episodeData && episodeData[0]?.series_id?.series_name}
          openModal={() => {
            setOpen(true);
          }}
          btnText="Add New "
        />
        <DataTable2
          data={episodeData && episodeData}
          columns={columns}
          pagination={pagination}
          setPagination={setPagination}
          loader={loader}
        />
        <AddEpisodeModal
          openModal={open}
          closeModal={() => {
            setOpen(false);
          }}
        />
        <DeleteModal
          title="delete episode"
          openModal={deleteData}
          closeModal={() => {
            setDeleteData(false);
          }}
          handleSubmit={deleteEpisodeData}
        />
        {/* <Modal
          openModal={openEpisode}
          closeModal={() => {
            setOpenEpisode(false);
          }}
        >
         
        </Modal> */}
        <Dialog
          open={openEpisode}
          onOpenChange={() => {
            setOpenEpisode(false);
          }}
        >
          <DialogContent className="!bg-[#0c0b0b] sm:max-w-[700px] max-w-full">
            <DialogHeader className="w-full">
              {/* <div className="flex flex-col "> */}
              <div className="flex justify-between items-center border-b border-inputBG w-full px-4 py-4">
                <h1 className="text-white text-xl font-semibold">Episode</h1>
                <div
                  className="bg-inputBG w-10 h-10 rounded-lg flex justify-center items-center shadow-3xl"
                  onClick={() => {
                    setOpenEpisode(false);
                  }}
                >
                  <IoMdClose className="text-white" />
                </div>
              </div>
            </DialogHeader>
            <DialogDescription>
              <div className="p-5">
                {rowData?.episode ? (
                  <ReactPlayer
                    url={rowData?.episode}
                    controls
                    width="100%"
                    height="350px"
                    // height="auto"
                  />
                ) : (
                  ""
                )}
              </div>
            </DialogDescription>
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
};

export default SeriesDetails;
